import { useState, useRef, useContext, useEffect } from "react";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import HeaderToolbar from "../Common/HeaderToolbar/HeaderToolbar";
import LinkWrapper from "../Global/LinkWrapper";
import { isAuthored } from "../../helpers/LinkHelper";
import { HEADING_LEVELS, PRIMARY, TERTIARY } from "../Global/GlobalConstants";
import Image from "../Global/Image/Image";
import "./PageHeader.scss";
import Title from "../Global/Title/Title";
import { IsMobileContent } from "../../media-queries/MediaQueries";
import Video from "../Global/Video";

const BASIC_CONTENT_DOC_TYPE = "dartcontainercombrxm:BasicContent";
const PAGE_HEADER_DOC_TYPE = "dartcontainercombrxm:PageHeader";
const PRODUCT_FAMILY_DOC_TYPE = "dartcontainercombrxm:ProductFamilyHeader";
const DEFAULT_CONTENT_HEIGHT_MIN = 325;
const HeroType = "hero";
const OFF = "off";

function PageHeader() {
  const DEFAULTCHARACTERSTOSHOW = 180;
  const AUTO_VALUE = "auto";
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);
  const contentRef = useRef();
  const imageRef = useRef();
  const readMoreLabel = window.globalProperties["readMoreLabel"] || "Read More";
  const readLessLabel = window.globalProperties["readLessLabel"] || "Read Less";

  const { document: documentRef, breadcrumbs } = component.getModels();
  const {
    componentStyle = PRIMARY,
    hideBreadcrumbs,
    pageDetailsImageHeight,
    pageDetailsImageWidth,
    type,
    textPosition,
    hasTextAreaBackground = OFF,
    readMoreCharacterCount = DEFAULTCHARACTERSTOSHOW,
    showReadMore,
  } = component.getParameters();

  var contentHeight = AUTO_VALUE;

  useEffect(() => {    
    contentHeight = DEFAULT_CONTENT_HEIGHT_MIN;
    setImageWidth(pageDetailsImageWidth);
    setImageHeight(pageDetailsImageHeight);
    
    if (imageHeight != AUTO_VALUE && imageHeight > contentRef.current.clientHeight) {
      contentHeight = imageHeight;
    }
  }, [imageRef, contentRef, pageDetailsImageHeight]);
    
  const showCta = isAuthored(link);
  const document = page.getContent(documentRef);
  if (!document) return null;

  const getContent = (data) => {
    if (!data) { data = {}; }
    const { contentType = "" } = data;
    switch (contentType) {
      case PAGE_HEADER_DOC_TYPE:
        const { pageDetails = {} } = data;
        return pageDetails;
      case PRODUCT_FAMILY_DOC_TYPE:
        const { familyDetails = {} } = data;
        return familyDetails;
      case BASIC_CONTENT_DOC_TYPE:
      default:
        return data;
    }
  };

  const _pushAllChildren = (arr, element) => {
    for (var i = 0; i < element.children.length; i++) {
      arr.push(element.children[i]);
    }
  };

  const _computeReadMore = (longDescription) => {
    const descriptionHtml = window.document.createElement('div');
    descriptionHtml.innerHTML = longDescription;
    const hasReadMore = showReadMore && descriptionHtml.textContent.length > readMoreCharacterCount;
    if (hasReadMore) {
      const elementStack = [];
      let charCount = 0;
      let foundEnd = false;
      _pushAllChildren(elementStack, descriptionHtml);
      while (elementStack !== undefined && elementStack.length > 0) {
        let currentChild = elementStack.shift();
        let currentChildText = currentChild.text || currentChild.textContent || "";
        if (foundEnd) {
          currentChild.parentElement.removeChild(currentChild);
        } else if ((currentChildText.length + charCount) <= readMoreCharacterCount) {
          charCount += currentChildText.length;
        } else {
          if (currentChild.children.length > 0) {
            _pushAllChildren(elementStack, currentChild);
          } else {
            currentChild.innerHTML = currentChild.innerHTML.substr(0, readMoreCharacterCount - charCount) + "...";
            charCount = readMoreCharacterCount;
            foundEnd = true;
          }
        }
      }
      return [descriptionHtml.innerHTML, hasReadMore];
    }
    return [longDescription, hasReadMore];
  };

  const {
    primaryTitle = "",
    description = {},
    link = {},
    image: imageLink = "",
    imageAltText = "",
    video = null,
    videoMuteHide = false,
  } = getContent(document.getData());

  const longDescription = page?.rewriteLinks(description.value) || "";
  const [shortDescription, hasReadMore] = _computeReadMore(longDescription);


  return (
    <>
      <HeaderToolbar
        hideBreadcrumbs={hideBreadcrumbs}
        breadcrumbs={breadcrumbs}
      />
      <div
        className={`${page.isPreview() ? "has-edit-button" : ""} page-header page-header--${componentStyle} ${(imageLink || hasTextAreaBackground !== OFF) ? "" : " page-header--no-image-no-text-bg"}${type === HeroType ? " page-header--hero" : " page-header--banner"}`}
      >
        <BrManageContentButton content={document} />
        <div className={`page-header__inner page-header__inner--${textPosition}`}>
          {video && (
            <Video
              className={`page-header__video-container ${type === HeroType ? " page-header__video-container--hero" : ""}`}
              video={video}
              componentStyle={componentStyle}
              isMuteHidden={videoMuteHide}
            />
          )}
          <div
            ref={contentRef}
            className={`page-header__content page-header--bg-${hasTextAreaBackground}`}
            style={(IsMobileContent() && type === HeroType) ? { height: AUTO_VALUE } : { height: `${contentHeight}px` }}
          >
            {primaryTitle && (
              <Title
                defaultLevel={HEADING_LEVELS.ONE}
                className={`page-header__header page-header__header--${componentStyle}`}
              >
                {primaryTitle}
              </Title>
            )}
            {description?.value && (
              <div
                className={`page-header__description-rte-wrapper page-header__description-rte-wrapper--${componentStyle}`}
              >
                <span dangerouslySetInnerHTML={{
                  __html: hasReadMore ? (
                    showFullDescription ? longDescription : shortDescription
                  ) : longDescription,
                }} />
                {hasReadMore && (
                  <button
                    type="button"
                    className="page-header__description-rte-wrapper-toggle"
                    onClick={() => setShowFullDescription(!showFullDescription)}
                  >
                    <span className="page-header__description-rte-wrapper-toggle-text">
                      {showFullDescription ? readLessLabel : readMoreLabel}
                    </span>
                    {showFullDescription ? (
                      <span className="page-header__description-rte-wrapper-toggle-icon darticon-arrow-up"></span>
                    ) : (
                      <span className="page-header__description-rte-wrapper-toggle-icon darticon-arrow-down"></span>
                    )}
                  </button>
                )}
              </div>
            )}
            {showCta && (
              <LinkWrapper
                className={`page-header__content__cta btn ${componentStyle === TERTIARY ? "btn--" + componentStyle : ""}`}
                link={link}
              />
            )}
          </div>
          {!video && imageLink && (
            <Image
              className="page-header__image"
              src={imageLink}
              alt={imageAltText || ""}
              width={pageDetailsImageWidth ? imageWidth : 0}
              height={pageDetailsImageHeight ? imageHeight : 0}
              imageRef={imageRef}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default PageHeader;