import React, { useEffect, useState, useRef } from "react";
import { BrPageContext, BrComponentContext } from "@bloomreach/react-sdk";
import SavedProductsCard from "./SavedProductsCard";
import SavedProducts from "../../services/SavedProducts";
import Modal from "../Common/Modal/Modal";
import "./SavedProductsController.scss";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Title from "../Global/Title/Title";
import { HEADING_LEVELS, SAVED_PRODUCTS_MUTATED_EVENT_NAME } from "../Global/GlobalConstants";

function SavedProductsController() {
  const page = React.useContext(BrPageContext);
  const component = React.useContext(BrComponentContext);
  const { document: documentRef, servletUrl, shareThisUrl } = component.getModels();

  const isFirstRender = useRef(true);
  const [products, setProducts] = useState([]);
  const [numberToShow, setNumberToShow] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);

  // Share This info
  const [shareThisFormData, setShareThisFormData] = useState({
    recipientEmail: "",
    emailSubject: "",
  });
  const [shareThisProcessing, setShareThisProcessing] = useState(false);
  const [shareThisValidationError, setShareThisValidationError] = useState("");
  const [shareThisSent, setShareThisSent] = useState(false);

  const NUMBERTOLOADMORE = 10;

  const { removeAll, toString } = SavedProducts();

  try {
    const printList = () => {
      window.print();
    };

    // Clears list of products
    const clearList = () => {
      removeAll();
    };

    const toggleModal = () => {
      if(modalOpen) {
        setShareThisSent(false);
      }
      setModalOpen(!modalOpen);
    };

    const handleShareThisChange = (event) => {
      setShareThisFormData(values =>
        ({...values, [event.target.name]: event.target.value}));
    };

    const handleShareThisSubmit = (event) => {
      event.preventDefault();

      var productCodes = products.reduce((accumulator, val) => {return accumulator + val.productCode + ",";}, "");
      productCodes = productCodes.substring(0, productCodes.length - 1);

      var formData = {
        ...shareThisFormData,
        productCodes
      };

      var shareThisRequest = shareThisUrl + "?";// + "?email=" etc

      for(var attr in formData) {
        if(!attr || attr == "") continue;
        shareThisRequest += attr + "=" + formData[attr] + "&";
      }

      shareThisRequest = shareThisRequest.substring(0, shareThisRequest.length - 1);

      setShareThisProcessing(true);

      fetch(shareThisRequest, {
        method: "post"
      })
      .then((response) => {
        setShareThisProcessing(false);
        setShareThisFormData({recipientEmail: ""});
        setShareThisFormData({emailSubject: ""});
        if (response.ok) {
          return;
        }
        else {
          throw response.text();
        }
      })
      .then(()=>{
          setShareThisValidationError("");
          setShareThisSent(true);
      })
      .catch((error)=>{
        if(error.then) {
          error.then((data) => {
            console.error(data);
            setShareThisValidationError(data);
          });
        }
        else {
          setShareThisValidationError("Unknown Error");
        }
      });
    };

    // Loads 10 more products
    const loadMore = () => {
      const loadMoreAmount = numberToShow + NUMBERTOLOADMORE;
      setNumberToShow(loadMoreAmount);
    };

    // Gets products
    const fetchProducts = () => {
      // Get list of products IDs as String
      const productIds = toString(); // Comes back as "product1__product2__..."

      if (productIds) {
        // Encodes string of IDs
        const productIdsEncoded = encodeURIComponent(productIds);

        // Adds productIDs to servlet to get product details
        const productsRequest = servletUrl + "?productCodes=" + productIdsEncoded + "&includeInactive=true&includeAllData=true";

        // Perform fetch
        fetch(productsRequest)
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw response;
          })
          .then((data) => {
            setProducts(data);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        setProducts([]);
      }
    };

    useEffect(() => {
      if (isFirstRender.current) {
        fetchProducts();
        isFirstRender.current = false;
        window.addEventListener(SAVED_PRODUCTS_MUTATED_EVENT_NAME, fetchProducts);

        return () => {
          window.removeEventListener(SAVED_PRODUCTS_MUTATED_EVENT_NAME, fetchProducts);
        };

      }
    }, []);


    const document = page.getContent(documentRef);
    if (!document) return null;

    const { productHeight, productWidth } = component.getParameters();

    const productCount = (products && products.length) || 0;

    const {
      title = null,
      discontinuedProductLabel = null,
      discontinuedProductDescription = null,
      itemCountLabel = null,
      printListLabel = null,
      clearListLabel = null,
      shareThisLabel = "Share This",
    } = document.getData();

    const labelsOutput = {
      titleLabel: title,
      discontinuedLabel: discontinuedProductLabel,
      discontinuedDescription: discontinuedProductDescription.value,
      itemCountLabel: itemCountLabel,
      printListLabel: printListLabel,
      clearListLabel: clearListLabel,
      shareThisLabel: shareThisLabel,
    };

    const productsOutput = () => {
      return products.slice(0, numberToShow).map((item, index) => {
        return (
          <li className="saved-products-controller__product" key={index}>
            <SavedProductsCard
              product={item}
              labels={labelsOutput}
              productImageHeight={productHeight}
              productImageWidth={productWidth}
            />
          </li>
        );
      });
    };

    const getTitle = () => {
      let titleOutput = title;
      if (titleOutput !== null) {
        titleOutput = titleOutput.replace("{}", productCount);
      }

      return titleOutput;
    };

    return (
      <div className="saved-products-controller">
        <div className="saved-products-controller__wrapper">
          {/* Header Section */}
          <div className="saved-products-controller__header-wrapper">
            <Title
              defaultLevel={HEADING_LEVELS.ONE}
              className="saved-products-controller__header-text"
            >
              {getTitle()}
            </Title>

            {/* Print and Clear List CTAs */}
            <div className="saved-products-controller__header-cta-wrapper">
              {products && productCount > 0 && (
                <button className="saved-products-controller__header-cta" onClick={toggleModal}>
                  <span className="saved-products-controller__header-cta-icon darticon-email"></span>
                  <b className="saved-products-controller__header-cta-label">
                    {labelsOutput["shareThisLabel"]}
                  </b>
                </button>
              )}
              <button className="saved-products-controller__header-cta" onClick={printList}>
                <span className="saved-products-controller__header-cta-icon darticon-fax"></span>
                <b className="saved-products-controller__header-cta-label">
                  {labelsOutput["printListLabel"]}
                </b>
              </button>
              <button className="saved-products-controller__header-cta" onClick={clearList}>
                <span className="saved-products-controller__header-cta-icon darticon-close"></span>
                <b className="saved-products-controller__header-cta-label">
                  {labelsOutput["clearListLabel"]}
                </b>
              </button>
            </div>
          </div>

          {/* Products List */}
          {products && productCount > 0 && (
            <div className="saved-products-controller__products-wrapper">
              <ul className="saved-products-controller__products-list">{productsOutput()}</ul>
            </div>
          )}

          {/* Load More CTA */}
          {products && productCount > numberToShow && (
            <div className="saved-products-controller__load-more-wrapper">
              <button className="btn btn--secondary" onClick={loadMore}>
                {window.globalProperties["loadMoreLabel"] || "Load More"}
              </button>
            </div>
          )}
        </div>
        <Modal className="saved-products-controller__share-this-modal" show={modalOpen} handleClose={toggleModal} lastElementSelector=".saved-products-controller__share-this-submit">
          <Title
            defaultLevel={HEADING_LEVELS.THREE}
            className="saved-products-controller__share-this-title"
          >
            {window.globalProperties["shareThisTitle"] || "Share My List"}
          </Title>
          <form className="saved-products-controller__share-this-form" onSubmit={handleShareThisSubmit}>
            <label htmlFor="recipientEmail">{window.globalProperties["recipientEmailLabel"] || "Recipient Email"}</label>
            <span className='saved-products-controller__share-this-notice'>
              {window.globalProperties["shareThisInstructions"] || "Note: Does not support multiple recipient emails"}
            </span>
            <input
              className={`saved-products-controller__share-this-input ${shareThisValidationError ? "error" : ""}`}
              name="recipientEmail"
              id="recipientEmail"
              type="email"
              value={shareThisFormData.recipientEmail}
              onChange={handleShareThisChange}
              {... (shareThisProcessing && {disabled: "disabled"})}
              {... (shareThisValidationError && {"aria-describedby": "share-this-validation"})}/>
            <label htmlFor="emailSubject">{window.globalProperties["subjectFieldLabel"] || "Subject"}</label>
            <input
              className={`saved-products-controller__share-this-input ${shareThisValidationError ? "error" : ""}`}
              name="emailSubject"
              id="emailSubject"
              type="text"
              value={shareThisFormData.emailSubject}
              maxLength="60"
              onChange={handleShareThisChange}
            />
            <p className='saved-products-controller__share-this-notice'>
              {window.globalProperties["shareThisDisclaimer"] || "The entered emails will not be used for any advertising or marketing purposes."}
            </p>
            {shareThisValidationError && (
              <div
                className="saved-products-controller__share-this-validation"
                id="share-this-validation"
                role="alert">
                {shareThisValidationError}
              </div>
            )}
            {shareThisProcessing ? (
              <button className="btn saved-products-controller__share-this-submit" type="submit" disabled="disabled">processing...</button>
            ) : (
              <button className="btn saved-products-controller__share-this-submit" type="submit">Send</button>
            )}
            {shareThisSent && (
              <div className="saved-products-controller__share-this-sent" role="alert">
                {window.globalProperties["emailSentMessage"] || "Sent"}
              </div>
            )}
          </form>
        </Modal>
      </div>
    );
  } catch (e) {
    console.error("SavedProducts error", e);
    return <ErrorComponent error={e} componentName={"SavedProducts"} />;
  }
}

export default SavedProductsController;
