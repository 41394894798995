import { useRef } from "react";
import "./Video.scss";

function Video({ video, componentStyle, isMuteHidden, isPauseHidden, isOverlayControlGroup, className, ...props }) {

  const pauseLabel = window.globalProperties["videoPauseLabel"] || "Pause";
  const playLabel = window.globalProperties["videoPlayLabel"] || "Play";
  const pauseAriaLabel = window.globalProperties["videoPauseAriaLabel"] || "Pause the autoplay video";
  const playAriaLabel = window.globalProperties["videoPlayAriaLabel"] || "Play the autoplay video";
  const muteLabel = window.globalProperties["videoMuteLabel"] || "Mute";
  const unmuteLabel = window.globalProperties["videoUnmuteLabel"] || "Unmute";
  const muteAriaLabel = window.globalProperties["videoMuteAriaLabel"] || "Mute the autoplay video";
  const unmuteAriaLabel = window.globalProperties["videoUnmuteAriaLabel"] || "Unmute the autoplay video";

  const videoRef = useRef();
  const playPauseRef = useRef();
  const muteUnmuteRef = useRef();

  const handlePlayPauseClick = () => {
    const video = videoRef && videoRef.current;
    const button = playPauseRef && playPauseRef.current;

    if(!video || !button) {
      return;
    }

    if (video.paused) {
        video.play();
        button.textContent = pauseLabel;
        button.ariaLabel = pauseAriaLabel;
    } else {
        video.pause();
        button.textContent = playLabel;
        button.ariaLabel = playAriaLabel;
    }
  }

  const handleMuteUnmuteClick = () => {
    const video = videoRef && videoRef.current;
    const button = muteUnmuteRef && muteUnmuteRef.current;

    if(!video || !button) {
      return;
    }

    if (video.muted) {
        video.muted = false;
        button.textContent = muteLabel;
        button.ariaLabel = muteAriaLabel;
    } else {
        video.muted = true;
        button.textContent = unmuteLabel;
        button.ariaLabel = unmuteAriaLabel;
    }
  }

  return (
    <div className={`${className} video-container`} {...props}>
      {(!isMuteHidden || !isPauseHidden) && (
        <div className={`video-container__control-group ${isOverlayControlGroup ? "overlay" : "inline-block"}`}>
          {!isMuteHidden && (
            <button
              ref={muteUnmuteRef}
              type="button"
              className={`video-container__control-button video-container__control-button--${componentStyle} ${isOverlayControlGroup ? "overlay" : "inline-block"}`}
              onClick={handleMuteUnmuteClick}
              aria-label={unmuteAriaLabel}
            >
              {unmuteLabel}
            </button>
          )}
          {!isPauseHidden && (
            <button
              ref={playPauseRef}
              type="button"
              className={`video-container__control-button video-container__control-button--${componentStyle} ${isOverlayControlGroup ? "overlay" : "inline-block"}`}
              onClick={handlePlayPauseClick}
              aria-label={pauseAriaLabel}
            >
              {pauseLabel}
            </button>
          )}
        </div>
      )}
      <video ref={videoRef} className="video-container__video" loop muted autoPlay controlsList="nodownload" disablePictureInPicture onClick={handlePlayPauseClick}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
}

export default Video;
