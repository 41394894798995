import { useState, useContext, useEffect } from "react";
import sanitizeHtml from 'sanitize-html'
import "./HotSpots.scss";
import Title from "../../Global/Title/Title";
import { HEADING_LEVELS, PRIMARY, TERTIARY, TEXT_ALIGNMENT } from "../../Global/GlobalConstants";
import { Desktop, Mobile, IsMobileContent } from "../../../media-queries/MediaQueries";
import VideoIFrame from "../../Common/VideoIFrame/VideoIFrame";

const HotSpots = ({ hotSpots, productUrl, productAPIErrorText, addYourBrandLabel, newProductLabel, parentComponentId }) => {
  const [activeHotSpot, setActiveHotSpot] = useState(-1);
  const [catalogImage, setCatalogImage] = useState(null);
  const [catalogImageAlternateText, setCatalogImageAlternateText] = useState(null);
  const [childProductName, setChildProductName] = useState(null);
  const [isProPlanetBool, setIsProPlanet] = useState(false);
  const [isCompostableBool, setIsCompBool] = useState(false);
  const [isAddBrand, setIsAddBrand] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [productDescription, setProductDescription] = useState(null);
  const [productCode, setProductCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [ctaLink, setCtaLink] = useState(null);
  const [isProduct, setIsProduct] = useState(false);
  const [isHover, setHover] = useState(false);
  const [isTileHover, setTileHover] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);
  const [isThumbClicked, setIsVideoThumbClicked] = useState(false);
  const isMobile = IsMobileContent();

  const renderHotSpots = () => {
    return hotSpots.map((item, index) => {
        const {x,y, productTileInfo, productData, hasInvalidProduct} = item;
        const unit = "%";
        return !hasInvalidProduct && (
          <div 
            key={`hotspot-container-${index}`}
            className="__spot"  
            style={{
              left: x + unit,
              top: Math.max(Math.min(50 + (y - 50), 95),5) + unit,
            }}
            onClick={(item)=>{
              clear()
              setIsProduct(productTileInfo.productcode !== null && productTileInfo.productcode !== "" && productData !== undefined);

              if(isProduct){
                setLoading(true);
                loadProductData(productData, index)
              }

              if(activeHotSpot === index) {
                setActiveHotSpot(-1);
              } else {
                setActiveHotSpot(index);
              }
            }}
            onMouseLeave={()=>{ 
              if(isMobile) return;
              setHover(false);
            }}
            onMouseOut={()=>{ 
              if(isMobile) return;
              setHover(false);
            }}
            onMouseMove={()=>{ 
              if(isMobile) return;
              setHover(true);
            }}
            onMouseOver={()=>{ 
              if(isMobile) return;
              setHover(true);
            }}
            onFocus={()=>{ 
              setHover(true);
            }}
            onMouseEnter={(item) => {         
              if(isMobile) return;
              if(activeHotSpot !== index){
                clear()
                setIsProduct(productTileInfo.productcode !== null && productTileInfo.productcode !== "" && productData !== undefined)
                setActiveHotSpot(index);
                if(productTileInfo.productcode !== null && productTileInfo.productcode !== "" && productData !== undefined){
                  setLoading(true);
                  loadProductData(productData, index)
                }
              }
            }}>
            <button type="button" id={`hotspot-button-${index}-${parentComponentId}`} className={activeHotSpot === index ? "__item __item--active" : "__item"}  tabIndex="0" aria-pressed={activeHotSpot === index}>
                <div className="__layer __layer--1" tabIndex="-1"></div>
                <div className="__layer __layer--2" tabIndex="-1"></div>
                <div className="__layer __layer--3" tabIndex="-1"></div>
            </button>
          </div>
        )
    })
  }

  const clear = () =>{
      setCatalogImage(null);
      setCatalogImageAlternateText(false);
      setChildProductName(false);
      setIsProPlanet(false);
      setProductDescription(false);
      setProductCode(false);
      setIsCompBool(false);
      setCtaLink(false);
      setIsNew(false);
      setIsAddBrand(false);
  }

  const loadProductData = (productData, index) => {
    const product = productData;

    if(product["isProPlanet"] !== undefined) {
      setIsProPlanet(product["isProPlanet"] ? true : false);
    }
    else {
      setIsProPlanet(false);
    }

    if (product["isCompostable"] !== undefined) {
      setIsCompBool(product["isCompostable"] ? true : false);
    }
    else {
      setIsCompBool(false);
    }

    if(product["isPrintable"] !== undefined || product["isEmbossable"] !== undefined) {
      if(product["isPrintable"] || product["isEmbossable"]) {
        setIsAddBrand(true);
      }
      else {
        setIsAddBrand(false);
      }
    }
    else {
      setIsAddBrand(false);
    }

    if (product["isNewProduct"] !== undefined) {
      setIsNew(product["isNewProduct"] ? true : false);
    }
    else {
      setIsNew(false);
    }

    setProductDescription(false);

    if (product["productCode"] !== undefined) {
      setProductCode(product["productCode"]);
    }
    else {
      setProductCode(false);
    }

    if (product["catalogImage"] !== undefined) {
      setCatalogImage(product["catalogImage"]);
    }
    else {
      setCatalogImage(false);
    }

    if (product["catalogImageAlternateText"] !== undefined) {
      setCatalogImageAlternateText(product["catalogImageAlternateText"]);
    }
    else {
      setCatalogImageAlternateText(false);
    }

    if (product["childProductName"] !== undefined) {
      setChildProductName(product["childProductName"]);
    }
    else {
      setChildProductName(false);
    }    

    if (product["url"] !== undefined) {
      setCtaLink(product["url"]);
    }
    else {
      setCtaLink(false);
    }            
    
    setLoading(false);
  }
 
  const handleClose = () =>{
    clear();
    setLoading(false);
    setActiveHotSpot(-1);
    setIsVideoThumbClicked(false);
  }

  const buildStyleAttributeObject = (x, y, unit = "%") => {
      const obj = {};
      if(isMobile){
        obj["right"] = 0;
        obj["left"] = 0;
        obj["bottom"] = 0;
        obj["top"] = 0;
        return obj;
      }
      if(x >= 50){
        obj["right"] = (100 - x) + unit;
      }else{
        obj["left"] = x + unit;
      }

      if(y >= 50){
        obj["bottom"] = (100 - y) + unit;
      }else{
        obj["top"] = Math.max(Math.min(50 + (y - 50), 95),5) + unit;
      } 
      return obj;
  }

  const LoadingBlock = (style) => (
        <div className="__hot-spot-content __hot-spot-content--active" style={style} key={`hot-spot-content-active`} onMouseEnter={()=>{setTileHover(true)}} onMouseLeave={()=>{ setTileHover(false) }}>
          <div className="__content __content--mock">
            <div className="__main __main--withmedia">                        
                <div className="__media __media--mock"><div></div></div>
                <div style={{alignSelf: "baseline"}}> 
                  <div className="__bar __bar--dark"></div>
                  <div className="__bar __bar--thin"></div>
                  <div className="__bar"></div>
                </div>
                <div className="__circle"></div>
            </div>
          </div>
      </div>
  )
  const focusOnContent = () => {
        let a = document.getElementById("hot-spot-content")
        if(a) a.focus();
  }

  const renderHotSpotContent = () => {
    if(activeHotSpot === -1) {
        return null;
    }

    const item = hotSpots[activeHotSpot];
    const {x,y} = item;
    const {title, body, subTitle, hasMedia, detailsLink, detailsLinkText, imageUrl,  mediaAltText, videoUrl, videoThumbnailUrl, ctaLinkNode, halfStyle} = item.productTileInfo;
    const style = buildStyleAttributeObject(x, y);

    let clsName = "__main";
    let addBrandBlock = null;
    let isNewBlock = null;
    let detailsBlock = null;
    let mediaBlock = null;
    let childProductNameBlock = null;
    let productDescBlock = null;
    let productCodeBlock = null;
    let isProPlanetBlock = null;
    let ctaLinkBlock = null;
    let titleBlock = null;
    let subtitleBlock = null;
    let bodyBlock = null;

    if(loading){
      style["width"] = "330px"
      return LoadingBlock(style);
    }

    if(isProPlanetBool){
      isProPlanetBlock = (<div className="__tag __tag--planet" aria-label="Pro Planet">
        {isCompostableBool ? (
          <img width="50" src="https://dart.widen.net/content/vsjmstuwtr/svg/ProPlanet-Leaf-CheckmarkwStroke-Green.svg?u=hsm95h"/>
        ):(
          <img width="50" src="https://dart.widen.net/content/haqggj6fsh/svg/ProPlanet-Leaf-CheckmarkwStroke-Blue.svg?u=xd7jz4"/>
        )}
      </div>)
    }

    if(catalogImage){
      clsName = clsName + " __main--withmedia"
      mediaBlock = (<div className="__media"><img className="__media-content __media-content--image" src={catalogImage} alt={catalogImageAlternateText} /></div>);
    } 
    else if(hasMedia && (imageUrl || videoUrl)) {
      clsName = clsName + " __main--withmedia"
      if(imageUrl) {
        mediaBlock = (<div className="__media"><img alt={mediaAltText} className="__media-content __media-content--image" src={imageUrl} /></div>);
      } else {
            // 
        let con = <a tabIndex="0"  onKeyUp={(e)=>{if(e.keyCode === 13){setIsVideoThumbClicked(true)}}} onClick={()=>{setIsVideoThumbClicked(true)}}>
                    <div className="__playbtn">
                      <img className="__media-content __media-content--image __media-content--image-clickable" alt={mediaAltText} src={videoThumbnailUrl} />
                      <div className="___circle"></div>
                      <div className="__triangle"></div>
                    </div>
                  </a>   
        if(isThumbClicked){
          con = <VideoIFrame handleBlur={false} video={videoUrl} title="" />
        }
        mediaBlock = (
          <div className="__media">
            {con}
          </div>
        );
      }
    }

    if(childProductName){
      childProductNameBlock = (<div className="__childproductname">{childProductName}</div>);
    }

    if(productCode){
      productCodeBlock = (
        <Title
          defaultLevel={HEADING_LEVELS.FIVE}
          className="__productcode">
            {`#${productCode}`}
        </Title>
      );
    }

    // 50/50 style does not have cta link
    if(!halfStyle || ctaLinkNode) {
      if(ctaLink) {
        if(ctaLinkNode) {
          ctaLinkBlock = (<a className="__ctaLink" tabIndex="0" href={ctaLinkNode}><div className="__arrow"><div className="__a-icon">&rsaquo;</div></div></a>);
        }
        else {
          ctaLinkBlock = (<a className="__ctaLink" tabIndex="0" href={ctaLink}><div className="__arrow"><div className="__a-icon">&rsaquo;</div></div></a>);
        }
      } 
      else if(ctaLinkNode) {
        ctaLinkBlock = (<a className="__ctaLink" tabIndex="0" href={ctaLinkNode}><div className="__arrow"><div className="__a-icon">&rsaquo;</div></div></a>);
      }
    }

    if(title) {
      titleBlock = (
        <Title
          defaultLevel={HEADING_LEVELS.FIVE}
          className="__title">
            {title}
        </Title>
      );
    }

    if(subTitle) {
      subtitleBlock = (
        <Title
          defaultLevel={HEADING_LEVELS.SIX}
          className="__subtitle">
            {subTitle}
        </Title>
      );
    }

    if(body) {
      bodyBlock = (<div className="__body" dangerouslySetInnerHTML={{__html: sanitizeHtml(body)}}></div>);
    }

    if(detailsLink) {
      detailsBlock = (<a className="__details" href={detailsLink}>{detailsLinkText}</a>);
    }

    if(isAddBrand) {
      addBrandBlock = (<div className="__tag __tag--brand">{addYourBrandLabel}</div>);
    }

    if(isNew) {
      isNewBlock = (<div className="__tag __tag--new">{newProductLabel}</div>);
    }        
      
    const espKeyClose = (e) =>{
      const oldActive = activeHotSpot;
      if(e.keyCode === 27){
        setTileHover(false); 
        setActiveHotSpot(-1); 
        clear();
        document.getElementById(`hotspot-button-${oldActive}-${parentComponentId}`).focus()
      }
    }
      
    let htmlBlock = null;
    if(isProduct){
      htmlBlock = (
        <div className="__hot-spot-content __hot-spot-content--active" tabIndex="0" id="hot-spot-content" style={style} key={`hot-spot-content-${activeHotSpot}`} onMouseEnter={()=>{setTileHover(true)}} onMouseLeave={()=>{ setTileHover(false) }} onKeyUp={(e)=>{espKeyClose(e)}}>
          <button className="__close darticon-close" onClick={()=>{clear(); setActiveHotSpot(-1);}}></button>
          <div className="__content __content">
            <div className="__tags __tags--top">
              {isNewBlock}
              {isProPlanetBlock}
            </div>
            <div className={clsName}>
              {mediaBlock}
              <div style={{alignSelf: "center"}}> 
                {titleBlock}
                {productCodeBlock} 
                {childProductNameBlock}
                {productDescBlock}
                {detailsBlock}
              </div>
              {ctaLinkBlock}
            </div>  
            <div className="__tags __tags--bottom">
              {addBrandBlock}               
            </div>
            <div tabIndex="0" style={{height:0, width:0, position: "absolute"}} onFocus={focusOnContent}>&nbsp;</div> 
          </div>
        </div>
      );
    } 
    else {
      if(halfStyle && !ctaLinkNode){
        clsName = "__main __main--5050"
      }
      htmlBlock = (
        <div className="__hot-spot-content __hot-spot-content--active" tabIndex="0" id="hot-spot-content" style={style} key={`hot-spot-content-${activeHotSpot}`} onMouseEnter={()=>{setTileHover(true)}} onMouseLeave={()=>{ setTileHover(false) }}  onKeyUp={(e)=>{espKeyClose(e)}}>
          <button className="__close darticon-close" onClick={()=>{clear(); setActiveHotSpot(-1);}}></button>
          <div className="__content">
            <div className="__tags __tags--top">
              {isNewBlock}
            </div>
            <div className={clsName}>
              {mediaBlock}
              <div style={{alignSelf: "center"}}> 
                {titleBlock}
                {subtitleBlock} 
                {bodyBlock}
                {detailsBlock}
              </div>
              {ctaLinkBlock}
            </div>
            <div className="__tags __tags--bottom">
              {addBrandBlock}               
            </div>   
            <div tabIndex="0" style={{height:0, width:0, position: "absolute"}} onFocus={focusOnContent}>&nbsp;</div>         
          </div>
        </div>
      );
    }
    
    setTimeout(()=>{
      focusOnContent();
    }, 500)

    return htmlBlock;
  }

  if(!Array.isArray(hotSpots)) {
      return null;
  }

  return (
    <div 
      id="hot-spots" 
      className="hot-spots" 
      key="hot-spots"
      onMouseMove={ () => {
        if(!isHover && !isTileHover){
          handleClose();
        }
      }}>
      {renderHotSpots()}
      {renderHotSpotContent()}
    </div>
  );
}

export default HotSpots;