import React from "react";
import { useEffect, useRef } from "react";
import "./Modal.scss";

function Modal({ handleClose, show, className, children, lastElementSelector }) {
  const modalWrapperRef = useRef();
  const showHideClass = show ? "modal--open" : "modal--closed";
  const closePopupLabel = window.globalProperties?.["closeModalPopup"]
    ? window.globalProperties?.["closeModalPopup"]
    : "close popup";
  
  useEffect(() => {
    const activeModal = document.querySelector(".modal--open > .modal__main");
    
    if (activeModal) {
      activeModal.focus();
      const modal = document.querySelector(".modal--open");
      const prevElSibActiveModal = modal.previousElementSibling;
      const playBtnImg = prevElSibActiveModal.querySelector(".btn");
      
      document.addEventListener("keydown", (event) => {
        if (event.key === "Escape") {
          handleClose();
          playBtnImg.focus();
        }
        // prevents user from leaving modal.
        if(document.activeElement.className === "modal__main" && event.key === "Tab" && event.shiftKey === true){
            event.preventDefault();
            if(lastElementSelector) {
              document.querySelector(lastElementSelector).focus();
            }
            else {
              const m = document.querySelector(".modal__main");
              m.lastChild.focus();
            }
        }
        else if(lastElementSelector && document.activeElement.matches(lastElementSelector) && event.key === "Tab" && event.shiftKey === false) {
          event.preventDefault();
          document.querySelector(".modal__main").focus();
        }
      });
    }
  }, [show]);

  function onClick(event) {
    if(!event || !modalWrapperRef || event.target == modalWrapperRef.current) {
      handleClose();
    }
  }

  return (
    <div className={`modal ${showHideClass} ${className}`} onClick={onClick} ref={modalWrapperRef}>
      <div tabIndex="0" className="modal__main">
        <div className="modal__header">
          <button className={`modal__button darticon-close`} tabIndex="0" onClick={() => handleClose()}
            title={closePopupLabel}
            aria-label={closePopupLabel}
            type="button"></button>
        </div>
        {children}
        {/* Fallback for if no selector is provided, doesn't stop  forward-tabbing, but stops back-tabbing*/}
        {!lastElementSelector && (
          <div tabIndex="0"></div>
        )}
      </div>
    </div>
  );
}

export default Modal;
