import { useState, useLayoutEffect, useRef, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { BrPageContext, BrComponentContext, BrManageContentButton } from "@bloomreach/react-sdk";
import HeaderToolbar from "../Common/HeaderToolbar/HeaderToolbar";
import LinkWrapper from "../Global/LinkWrapper";
import ErrorComponent from "../Common/ErrorComponent/ErrorComponent";
import Image from "../Global/Image/Image";
import Title from "../Global/Title/Title";
import Video from "../Global/Video";
import HotSpots from "./HotSpots/HotSpots"
import { GetGeneralLinkUrl, isAuthored, getUrlPath } from "../../helpers/LinkHelper";
import { HEADING_LEVELS, PRIMARY, TERTIARY, TEXT_ALIGNMENT } from "../Global/GlobalConstants";
import { IsTabletOrSmallerContent, SmallMedium, Desktop, Mobile } from "../../media-queries/MediaQueries";
import { getProductsByCodes } from "../../helpers/GlobalHelpers";
import sanitizeHtml from 'sanitize-html'
import "./ShoppableBanner.scss";

function ShoppableBanner() {
  const hotspotImageSelector = ".shoppable-banner__media__image";

  const [contentHeight, setContentHeight] = useState("auto");
  const [activeInfoPanel, setActiveInfoPanel] = useState(null);
  const [activeProductPanel, setActiveProductPanel] = useState(null);
  const contentRef = useRef();
  const imageWrapperRef = useRef();
  const isTabletOrSmaller = IsTabletOrSmallerContent();

  const {
    newProductLabel = "",
    addYourBrandLabel = "",
    moreColorsPrintsAvailableLabel = "",
    productAPIErrorText = ""
  } = window.globalProperties;

  let hotSpots = [];
  let productCodes = [];
  let productServletAddress = '';
  let stlMediaHeight = 0;
  let stlMediaWidth = 0;
  const [loaded, setLoaded] = useState(false);

  function resizeImage() {
    if (isTabletOrSmaller) {
      setContentHeight("auto");
      return;
    }

    const actualContentHeight =
      contentRef && contentRef.current && contentRef.current.clientHeight
        ? contentRef.current.clientHeight
        : "auto";

    if (!stlMediaHeight) {
      setContentHeight(actualContentHeight);
    }
    else if (stlMediaHeight > actualContentHeight) {
      setContentHeight(stlMediaHeight);
    }
  }

  function createHeaderContext(innerHTML) {
    if(!innerHTML) { return null; }

    const headerTextFakeDiv = window.document.createElement('div');
    headerTextFakeDiv.innerHTML = innerHTML;
    const headerText = headerTextFakeDiv.textContent.replaceAll("\n\n", "\n");
    const headerLines = headerText.split("\n");

    return {
      headerText: headerText.replaceAll("\n","<br/>"),
      ariaLabel: headerText.replaceAll("\n", " "),
    };
  }

  function findHotSpotsByProductCode(hotSpots, productCode) {
    var matchingHotSpots = [];
    for (var spot of hotSpots) {
      let spotProductCode = spot.productTileInfo.productcode;
      if (spotProductCode.replace("#", "") === productCode) {
        matchingHotSpots.push(spot);
        break;
      } else {
        continue;
      }
    }

    return matchingHotSpots;
  }

  useLayoutEffect(()=> {
    resizeImage();
    window.addEventListener('resize', resizeImage);
    return () => window.removeEventListener('resize', resizeImage);
  }, [isTabletOrSmaller]);

  const page = useContext(BrPageContext);
  const component = useContext(BrComponentContext);

  useEffect(() => {
    if (productCodes && productCodes.length > 0) {
      getProductsByCodes(productCodes, productServletAddress)
        .then((productData) => {
          if (productData) {
            productCodes.forEach((productCode) => {
              var targetProductData = productData.find((dataElement) => dataElement.productCode.toUpperCase() === productCode.toUpperCase());
              var hotSpotsWithProduct = findHotSpotsByProductCode(hotSpots, productCode);

              hotSpotsWithProduct.forEach((hotSpot) => {
                hotSpot.productData = targetProductData;
                hotSpot.hasInvalidProduct = targetProductData === undefined;
              });
            });
          }

          setLoaded(true);
        })
    } else {
      setLoaded(true);
    }
  }, [productCodes]);

  try {
    const { document: documentRef, productServletURL } = component.getModels();
    const document = page.getContent(documentRef);
    
    if (!document) return null;

    productServletAddress = productServletURL;

    const {
      callToActionLink = {},
      description = {},
      imageLink = "",
      imageAltText = "",
      specialLink = {},
      specialLinkDescription = "",
      title = {},
      video = "",
      hasHotSpots = "",
      hotSpotListCompound = {},
      id = ""
    } = document.getData();

    const {
      componentStyle = PRIMARY,
      mediaPosition = TEXT_ALIGNMENT.LEFT,
      mediaHeight = 0,
      mediaWidth = 0
    } = component.getParameters();

    stlMediaHeight = mediaHeight;
    stlMediaWidth = mediaWidth;

    const showCta = isAuthored(callToActionLink);
    const specialLinkUrl = GetGeneralLinkUrl(specialLink, page);
    const specialLinkAuthored = specialLinkUrl && specialLink.label;
    const headerContext = createHeaderContext(page?.rewriteLinks(sanitizeHtml(title.value)));

    if(hasHotSpots && Array.isArray(hotSpotListCompound.hotSpotItem)){
        hotSpots = hotSpotListCompound.hotSpotItem;
    }

    let hotSpotProductCodes = [];

    for (let item of hotSpots) {
      let code = item.productTileInfo.productcode;
      if (code !== "") {
        hotSpotProductCodes.push(code.replace("#", ""));
      }
    }

    if(hotSpotProductCodes.length > 0) {
      productCodes = hotSpotProductCodes;
    }

    return loaded && (
      <div className={`shoppable-banner shoppable-banner--${componentStyle} ${specialLinkAuthored ? "with-message" : ""}`} >
        <div className="shoppable-banner__inner">
        {specialLinkAuthored && (
          <div className="shoppable-banner__message shop">
            <a 
              href={GetGeneralLinkUrl(specialLink, page)}
              className="shoppable-banner__message__link">
                {specialLink.label}
            </a>
            {specialLinkDescription && (
              <>
                &nbsp;
                <span className="shoppable-banner__message__text">
                  {specialLinkDescription}
                </span>
              </>
            )}
          </div>
        )}
      
          <div className={`shoppable-banner__banner shoppable-banner__banner--${componentStyle} media-${mediaPosition}`} ref={contentRef}>

            {title.value && (
              <Title
                defaultLevel={HEADING_LEVELS.ONE}
                className={`shoppable-banner__banner__header shoppable-banner__banner__header--${componentStyle}`}
                aria-label={headerContext && headerContext.ariaLabel}
              >
                {headerContext && headerContext.headerText &&
                  (headerContext.headerText)
                }
              </Title>
            )}
            <span
              className={`shoppable-banner__banner__description shoppable-banner__banner__description--${componentStyle}`}
              dangerouslySetInnerHTML={{
                __html: page?.rewriteLinks(sanitizeHtml(description.value)),
              }}>
            </span>
            {showCta && (
              <LinkWrapper
                className={`shoppable-banner__banner__cta btn btn--${componentStyle}`}
                link={callToActionLink}
              />
            )}
          </div>
          <div className={`shoppable-banner__media media-${mediaPosition}`} ref={imageWrapperRef}>
            <HotSpots 
              hotSpots={hotSpots} 
              productUrl={productServletURL} 
              newProductLabel={newProductLabel} 
              addYourBrandLabel={addYourBrandLabel} 
              productAPIErrorText={productAPIErrorText}
              parentComponentId={id}
            />
            {video ? (
              <Video
                className="shoppable-banner__media__video"
                video={video}
                componentStyle={componentStyle}
                isMuteHidden={true}
                isOverlayControlGroup={true}
              />
            ) : (
              <Image
                className="shoppable-banner__media__image"
                src={imageLink}
                alt={imageAltText}
                onLoad={resizeImage}
                style={stlMediaHeight ? {} : { height: contentHeight }}
                height={stlMediaHeight}
                width={stlMediaWidth}
              />
            )}
          </div>
        </div>
      </div>
    );
  } catch (e) {
    console.error("ShoppableBanner error", e);
    return <ErrorComponent error={e} componentName={"ShoppableBanner"} />;
  }
}

export default ShoppableBanner;
